@import '~react-phone-number-input/style.css';
.MobileNumberInput {
  position: relative;
  &-label {
    position: absolute;
    top: 1px;
    height: calc(100% - 2px);
    font-size: 16px;
    color: #a9a9a9;
    left: 60px;
    right: 3px;
    background: white;
    padding: 0 5px;
    z-index: 2;
    display: inline-flex;
    align-items: center;
    pointer-events: none;
    transform-origin: left center;
    transition: 0.2s font-size, 0.2s top;
    &-focused {
      top: -8px;
      right: auto;
      height: auto;
      color: black;
      font-size: 12px;
    }
  }
  .PhoneInput {
    position: relative;
    --PhoneInputCountrySelect-marginRight: 0.2em;
    --PhoneInputCountrySelectArrow-width: 0.3em;
    &.is-danger {
      input {
        border-color: var(--red);
      }
    }
    input {
      border-radius: 4px;
      border: 1px solid #b7b7b7;
      color: black;
      max-width: 100%;
      width: 100%;
      box-shadow: none;
      -moz-appearance: none;
      -webkit-appearance: none;
      align-items: center;
      box-shadow: none;
      display: inline-flex;
      font-size: 16px;
      height: 2.5em;
      justify-content: flex-start;
      line-height: 1.5;
      padding: calc(0.5em - 1px) calc(0.75em - 1px);
      padding-left: 65px;
      position: relative;
      vertical-align: top;
      outline: none;
      width: 100%;
      &::placeholder {
        color: #a9a9a9;
      }
      &:focus {
        border: 2px solid #007aff;
      }
    }

    .PhoneInputCountry {
      position: absolute;
      height: 100%;
      margin: 0;
      width: 65px;
      top: 0;
      left: 15px;
      z-index: 2;
    }

    .PhoneInputCountryIcon {
      color: var(--dark-75);
      border: 0;
      box-shadow: none;
    }
  }

  &-no-border {
    input {
      border: none !important;
    }
  }
}
