.ImgAutoplay {
  max-height: 100vh;
  max-width: 100vw;
  width: 100%;
  height: 100%;
  overflow: hidden;

  &:after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    height: 100vh;
    width: 100%;
    background: linear-gradient(
      to top,
      rgba(black, 0.7) 0%,
      rgba(black, 0.5) 10%,
      transparent 100%
    );
  }

  &-disabled {
    figure {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      &:not(:first-child) {
        display: none;
      }
      img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }
  }

  &:not(.ImgAutoplay-disabled) {
    figure {
      position: absolute;
      top: -5vmax;
      right: -5vmax;
      bottom: -5vmax;
      left: -5vmax;
      opacity: 0;

      img {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
      }
      &:first-child {
        animation: a 18s linear infinite;
      }

      &:nth-child(2) {
        animation: b 18s 6s linear infinite;
      }

      &:nth-child(3) {
        animation: c 18s 12s linear infinite;
      }
    }
  }
}

@keyframes a {
  0% {
    opacity: 0;
    transform: translate3d(5vmax, 2vmax, 0) scale(1.1);
  }
  5% {
    opacity: 1;
  }
  33% {
    opacity: 1;
  }
  38% {
    opacity: 0;
    transform: translate3d(0, -2vmax, 0) scale(1);
  }
}

@keyframes b {
  0% {
    opacity: 0;
    transform: translate3d(-2vmax, 0, 0) scale(1);
  }
  5% {
    opacity: 1;
  }
  33% {
    opacity: 1;
  }
  38% {
    opacity: 0;
    transform: translate3d(2vmax, -2vmax, 0) scale(1.1);
  }
}

@keyframes c {
  0% {
    opacity: 0;
    transform: translate3d(0, 2vmax, 0) scale(1);
  }
  5% {
    opacity: 1;
  }
  33% {
    opacity: 1;
  }

  38% {
    opacity: 0;
    transform: translateY(-2vmax) scale(1.1);
  }
}
