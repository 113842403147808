@mixin apply-background($theme, $color) {
  &-theme-#{$theme} {
    .ReviewFeedTemplateCard-bg {
      background: $color;
      color: invert($color);
    }
  }
}

@mixin line-clamp($lines: 3) {
  display: -webkit-box;
  -webkit-line-clamp: $lines;
  -webkit-box-orient: vertical;

  overflow: hidden;
}

.ReviewFeedTemplateCard {
  @include apply-background(light, white);
  @include apply-background(dark, black);

  &-flayr-brand {
    &:before {
      content: 'nishi.app';
    }
  }
  &-comments {
    &:before,
    &:after {
      content: '"';
    }
  }
  &-default {
    .ReviewFeedTemplateCard {
      &-bg {
        padding: 2px;
        display: grid;
        align-items: stretch;
      }

      &-inner {
        padding: 20px 25px;
        display: grid;
        align-items: center;
        width: 100vw;
        height: 100vh;
        grid-template-rows: auto auto auto 1fr auto;
      }

      &-heading {
        text-align: center;
        font-weight: 500;
        font-size: 2.5em;
      }

      &-verified-by {
        text-align: center;
      }

      &-flayr-brand {
        display: block;
        margin: 5px auto;
        width: 100px;
        height: 28px;
        background-image: url('/flayr-logo.png');
        background-size: contain;
        &:before {
          content: '';
        }
      }

      &-comments {
        margin-top: 20px;
        margin-bottom: 10px;
        text-align: center;
        padding: 0 40px;
        font-size: 1.6em;
        font-weight: 300;
        @include line-clamp(9);
      }
      &-customer {
        margin-bottom: 20px;
        text-align: right;
        font-size: 1.2em;
        padding-right: 50px;
      }

      &-website {
        text-align: center;
        font-weight: 300;
      }
    }
  }

  &-1 {
    position: relative;
    padding-bottom: 30px;

    .ReviewFeedTemplateCard {
      &-bg {
        // min-height: calc(100vw - 4rem);
        display: grid;
        .pattern {
          position: absolute;
          display: none;
          svg {
            width: 45%;
            height: 45%;
          }
          $top: -5px;
          $left: -15px;
          &-top-left {
            top: $top;
            left: $left;
            display: block;
          }

          &-top-right {
            top: $top;
            right: $left;
            display: block;
            transform: scaleX(-1);
          }
          &-bottom-left {
            bottom: $top;
            left: $left;
            display: block;
            transform: scaleY(-1);
          }

          &-bottom-right {
            bottom: $top;
            right: $left;
            display: block;
            transform: scaleY(-1) scaleX(-1);
          }
        }
        padding: 30px;
        position: relative;
      }
      &-inner {
        display: grid;
        padding: 5px;
        width: calc(100vw - 60px);
        height: calc(100vw - 60px);
      }
      &-heading {
        text-align: center;
        font-size: 3.3em;
        order: 1;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      &-comments {
        order: 2;
        margin-top: 20px;
        text-align: center;
        font-size: 1.8em;
        font-weight: 300;
        padding: 0 10px;
        min-height: 3.5em;
        margin-bottom: 10px;
        @include line-clamp(8);
      }
      &-customer {
        text-align: right;
        order: 3;
        padding-right: 45px;
        font-size: 1.4em;
      }

      &-verified-by {
        order: 4;
        text-align: center;
        position: absolute;
        left: 0;
        width: 100%;
        bottom: 5px;
      }

      &-flayr-brand {
        &:before {
          content: 'nishi.app';
        }
      }

      &-website {
        display: none;
      }
    }
  }

  &-2 {
    &.ReviewFeedTemplateCard {
      overflow: auto;
    }
    .ReviewFeedTemplateCard {
      &-bg {
        padding: 10px 50px;
        position: relative;
        min-height: 490px;
        min-width: 490px;
      }

      &-inner {
        display: grid;
        padding: 0 40px;
        display: grid;
        grid-template-areas:
          'img img img img'
          'desc desc desc desc'
          'cust cust cust cust'
          'brand brand brand brand';
        grid-template-columns: repeat(4, 1fr);
      }

      &-image {
        order: 1;
        grid-area: img;
        padding: 0 15px;
      }

      &-brand-logo {
        bottom: 2rem;
        right: 2rem;
        position: absolute;
      }

      &-comments {
        margin-top: 15px;
        order: 2;
        text-align: center;
        font-weight: 400;
        font-size: 1.1em;
        grid-area: desc;

        @include line-clamp(4);
      }

      &-customer {
        text-align: right;
        order: 3;
        padding-right: 15px;
        margin-bottom: 10px;
        margin-top: 5px;
        grid-area: cust;
        &:before {
          content: '- ';
        }
      }

      &-verified-by {
        order: 4;
        text-align: center;
        font-size: 0.9em;
        grid-area: brand;
        margin: 5px 0;
      }

      &-heading {
        display: none;
      }

      &-website {
        display: none;
      }
    }
  }

  &-3 {
    .ReviewFeedTemplateCard {
      &-bg {
        position: relative;
        min-height: calc(100vw - 4rem);
        display: grid;
      }

      &-inner {
        position: absolute;
        bottom: 0;
        padding: 5px;
        text-align: center;
        display: grid;
        width: 100%;
        font-weight: 500;
      }

      &-comments {
        order: 1;
        margin-bottom: 1px;
        font-size: 1.2em;
        padding: 0 4px;
        @include line-clamp(4);
      }

      &-customer {
        order: 2;
        text-align: right;
        padding-right: 30px;
        font-size: 1.1em;
        margin-bottom: -15px;
        &:before {
          content: '- ';
        }
      }

      &-verified-by {
        order: 3;
      }

      &-heading {
        display: none;
      }

      &-website {
        display: none;
      }
    }
  }

  &-4 {
    .ReviewFeedTemplateCard {
      &-bg {
        overflow: hidden;
      }

      &-inner {
        text-align: center;
        display: grid;
        height: 100vh;
        > div:not(.ReviewFeedTemplateCard-content) {
          display: none;
        }
      }

      &-content {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr 1fr;
      }

      &-brand-logo {
        align-self: center;
        justify-self: center;
        transform: translate(10%, -10%);
      }

      &-image {
        position: relative;
        figure {
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          overflow: visible;
        }
        &:nth-of-type(1) {
          transform: scale(1.1) translate(4%, 4%);
        }
        &:nth-last-of-type(1) {
          transform: scale(1.1) translate(-4%, -4%);
        }
      }

      &-comments {
        padding: 5px;
        order: 1;
        margin-bottom: 1px;
        font-size: 1.3em;
        @include line-clamp(5);

        &:before,
        &:after {
          content: '"';
        }
      }

      &-text {
        padding: 30px 30px 5px 5px;
        display: grid;
        align-items: center;
        grid-template-rows: 1fr 1.5em auto;
        & > :first-child() {
          order: 1;
        }
        & > :nth-child(2) {
          order: 2;
        }
        & > :last-child {
          order: 3;
          font-size: 0.7em;
        }
      }

      &-customer {
        text-align: right;
        padding-right: 8px;
        font-size: 1.2em;
        &:before {
          content: '- ';
        }
      }

      &-verified-by {
        order: 3;
      }

      &-heading {
        display: none;
      }

      &-website {
        display: none;
      }
    }
  }
}
