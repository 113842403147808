@mixin line-clamp($lines: 3) {
  display: -webkit-box;
  -webkit-line-clamp: $lines;
  -webkit-box-orient: vertical;

  overflow: hidden;
}
.ReviewTemplateCard {
  padding: 5px;
  &-flayr-brand {
    &:before {
      content: 'nishi.app';
    }
  }
  &-comments {
    &:before,
    &:after {
      content: '"';
    }
  }
  &-default {
    .ReviewTemplateCard {
      &-bg {
        background: linear-gradient(to right, #01b1ea 20%, #82318e 100%);
        padding: 2px;
        // transform: scale(0.75);
        // height: 500px;
        // width: 500px;
        // min-height: 500px;
        // min-width: 500px;
        display: grid;
        align-items: stretch;
      }

      &-inner {
        background: white;
        padding: 20px 25px;
        // min-height: calc(100vw - 4rem);
        display: grid;
        align-items: center;
      }

      &-heading {
        text-align: center;
        font-weight: 500;
        font-size: 1.5em;
      }

      &-verified-by {
        text-align: center;
        font-weight: lighter;
        color: #464646;
      }

      &-flayr-brand {
        display: block;
        margin: 5px auto;
        width: 100px;
        height: 28px;
        background-image: url('/flayr-logo.png');
        background-size: contain;
        &:before {
          content: '';
        }
      }

      &-comments {
        margin-top: 20px;
        margin-bottom: 10px;
        text-align: center;
        font-size: 1.4em;
        font-weight: 300;
        color: #464646;
        @include line-clamp(9);
      }
      &-customer {
        margin-bottom: 20px;
        text-align: right;
        color: #464646;
      }

      &-website {
        text-align: center;
        font-weight: 300;
      }
    }
  }

  &-1 {
    position: relative;
    padding-bottom: 30px;

    .ReviewTemplateCard {
      &-bg {
        min-height: calc(100vw - 4rem);
        display: grid;
        .pattern {
          position: absolute;
          display: none;
          svg {
            width: 45%;
            height: 45%;
          }
          $top: -10px;
          $left: -10px;
          &-top-left {
            top: $top;
            left: $left;
            display: block;
          }

          &-top-right {
            top: $top;
            right: $left;
            display: block;
            transform: scaleX(-1);
          }
          &-bottom-left {
            bottom: $top;
            left: $left;
            display: block;
            transform: scaleY(-1);
          }

          &-bottom-right {
            bottom: $top;
            right: $left;
            display: block;
            transform: scaleY(-1) scaleX(-1);
          }
        }
        padding: 30px;
        position: relative;
      }
      &-inner {
        color: #2954a2;
        display: grid;
        padding: 5px;
      }
      &-heading {
        text-align: center;
        font-size: 2.2em;
        order: 1;
      }
      &-comments {
        order: 2;
        margin-top: 20px;
        text-align: center;
        font-size: 1.8em;
        font-weight: 300;
        padding: 0 10px;
        min-height: 3.5em;

        margin-bottom: 10px;

        @include line-clamp(8);
      }
      &-customer {
        text-align: right;
        order: 3;
        padding-right: 45px;
        color: rgb(43, 42, 42);
        font-size: 1.2em;
      }

      &-verified-by {
        order: 4;
        text-align: center;
        position: absolute;
        left: 0;
        right: 0;
        bottom: -25px;
      }

      &-flayr-brand {
        &:before {
          content: 'nishi.app';
        }
      }

      &-website {
        display: none;
      }
    }
  }

  &-2 {
    &.ReviewTemplateCard {
      overflow: auto;
    }
    .ReviewTemplateCard {
      &-bg {
        background-color: rgb(243, 184, 194);
        padding: 20px 50px;
        margin: -5px;
        position: relative;
        min-height: 490px;
        min-width: 490px;
      }

      &-inner {
        display: grid;
        padding: 0 40px;
      }

      &-image {
        order: 1;
      }

      &-brand-logo {
        bottom: 2rem;
        right: 2rem;
        position: absolute;
      }

      &-comments {
        margin-top: 15px;
        order: 2;
        color: white;
        text-align: center;
        font-weight: 400;
        font-size: 1.1em;

        @include line-clamp(4);
      }

      &-customer {
        text-align: right;
        order: 3;
        color: white;
        padding-right: 15px;
        margin-bottom: 15px;
        &:before {
          content: '- ';
        }
      }

      &-verified-by {
        order: 4;
        text-align: center;
        font-weight: lighter;
        color: white;
        font-size: 0.9em;
      }

      &-heading {
        display: none;
      }

      &-website {
        display: none;
      }
    }
  }

  &-3 {
    .ReviewTemplateCard {
      &-bg {
        margin: -5px;
        position: relative;
        min-height: calc(100vw - 4rem);
        display: grid;
      }

      &-inner {
        position: absolute;
        bottom: 0;
        padding: 5px;
        text-align: center;
        color: white;
        display: grid;
        text-shadow: 0 0 1px rgba($color: #000000, $alpha: 0.4);
        width: 100%;
        font-weight: 500;
      }

      &-comments {
        order: 1;
        margin-bottom: 1px;
        font-size: 1.2em;
        padding: 0 4px;
        @include line-clamp(5);
      }

      &-customer {
        order: 2;
        text-align: right;
        padding-right: 30px;
        font-size: 1.1em;
        margin-bottom: 5px;
        &:before {
          content: '- ';
        }
      }

      &-verified-by {
        order: 3;
      }

      &-heading {
        display: none;
      }

      &-website {
        display: none;
      }
    }
  }

  &-4 {
    .ReviewTemplateCard {
      &-bg {
        margin: -5px;
        background: #d0caa0;
        overflow: hidden;
      }

      &-inner {
        text-align: center;
        color: white;
        display: grid;
        min-height: calc(100vw - 1rem);
        > div:not(.ReviewTemplateCard-content) {
          display: none;
        }
      }

      &-content {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr 1fr;
      }

      &-brand-logo {
        align-self: center;
        justify-self: center;
        transform: translate(10%, -10%);
      }

      &-image {
        position: relative;
        figure {
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          overflow: visible;
        }
        &:nth-of-type(1) {
          img {
            transform: scale(1.1) translate(5%, 5%);
          }
        }
        &:nth-last-of-type(1) {
          img {
            transform: scale(1.1) translate(-5%, -5%);
          }
        }
      }

      &-comments {
        padding: 4px;
        order: 1;
        margin-bottom: 1px;
        font-size: 1.1em;
        padding: 0 4px;

        @include line-clamp(7);

        &:before,
        &:after {
          content: '';
        }
      }

      &-text {
        padding: 30px 30px 5px 5px;
        display: grid;
        align-items: center;
        grid-template-rows: 1fr 1.5em auto;
        & > :first-child() {
          order: 1;
        }
        & > :nth-child(2) {
          order: 2;
        }
        & > :last-child {
          order: 3;
          font-size: 0.7em;
        }
      }

      &-customer {
        text-align: right;
        padding-right: 8px;
        font-size: 0.9em;
        &:before {
          content: '- ';
        }
      }

      &-verified-by {
        // display: none;
        order: 3;
      }

      &-heading {
        display: none;
      }

      &-website {
        display: none;
      }
    }
  }
}
