.SlideInModal {
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 13500;
  width: 100%;
  transform: translate3d(0, 100%, 0);
  display: none;
  max-height: 100%;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  transition-property: transform;
  will-change: transform;

  &-below-popup {
    z-index: 10000;
    .SlideInModal {
      &-backdrop {
        z-index: 10600;
      }

      &-content {
        z-index: 10601;
      }
    }
  }

  &-backdrop {
    z-index: 13600;
  }

  &-content {
    position: absolute;

    width: 100%;
    will-change: transform;
    transition: transform 0.3s;
    z-index: 13601;
    max-height: 100vh;
    overflow-y: auto;
    &-slide {
      &-top {
        top: 50%;
        transform: translate3d(0, calc(-100vh - 100%), 0);
        &.SlideInModal-content-open {
          transform: translate3d(0, -50%, 0);
          backface-visibility: hidden;
        }
      }
      &-right {
        top: 0;
        transform: translate3d(calc(100vw + 100%), 0, 0);
        &.SlideInModal-content-open {
          transform: translate3d(0, 0, 0);
          backface-visibility: hidden;
        }
      }

      &-bottom {
        top: 50%;
        transform: translate(0, calc(100vh + 100%));
        &.SlideInModal-content-open {
          transform: translate(0, -50%) scale(1, 1);
          backface-visibility: hidden;
        }
      }
    }
    &-no-animation {
      transition: none;
      display: none;
      &.SlideInModal-content-slide {
        &-top {
          &.SlideInModal-content-open {
            display: block;
            top: 50%;
            backface-visibility: hidden;
            transform: translateY(-50%) translateZ(0) scale(1, 1);
          }
        }
      }
    }

    &-fullscreen {
      &.SlideInModal-content-no-animation {
        &.SlideInModal-content-open {
          top: 0;
          left: 0;
          transform: none;
        }
      }
    }
  }
}
