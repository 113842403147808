@mixin overlay($offset: 0) {
  bottom: $offset;
  left: $offset;
  position: absolute;
  right: $offset;
  top: $offset;
}

%overlay {
  @include overlay;
}

$dimensions: 16 24 32 48 64 96 128 !default;

.img {
  display: block;
  position: relative;

  img {
    display: block;
    height: auto;
    width: 100%;
    &.h-full {
      height: 100%;
    }
    &.w-full {
      width: 100%;
    }
  }

  // Ratio
  &.img-square,
  &.img-1by1,
  &.img-5by4,
  &.img-4by3,
  &.img-3by2,
  &.img-5by3,
  &.img-16by9,
  &.img-2by1,
  &.img-3by1,
  &.img-4by5,
  &.img-3by4,
  &.img-2by3,
  &.img-3by5,
  &.img-9by16,
  &.img-1by2,
  &.img-1by3 {
    img,
    .img-ratio {
      @extend %overlay;

      height: 100%;
      width: 100%;
    }
  }

  &.img-square,
  &.img-1by1 {
    padding-top: 100%;
  }

  &.img-5by4 {
    padding-top: 80%;
  }

  &.img-4by3 {
    padding-top: 75%;
  }

  &.img-3by2 {
    padding-top: 66.6666%;
  }

  &.img-5by3 {
    padding-top: 60%;
  }

  &.img-16by9 {
    padding-top: 56.25%;
  }

  &.img-2by1 {
    padding-top: 50%;
  }

  &.img-3by1 {
    padding-top: 33.3333%;
  }

  &.img-4by5 {
    padding-top: 125%;
  }

  &.img-3by4 {
    padding-top: 133.3333%;
  }

  &.img-2by3 {
    padding-top: 150%;
  }

  &.img-3by5 {
    padding-top: 166.6666%;
  }

  &.img-9by16 {
    padding-top: 177.7777%;
  }

  &.img-1by2 {
    padding-top: 200%;
  }

  &.img-1by3 {
    padding-top: 300%;
  }

  // Sizes
  @each $dimension in $dimensions {
    &.img-#{$dimension}x#{$dimension} {
      height: $dimension * 1px;
      width: $dimension * 1px;
      img {
        height: 100%;
      }
    }
  }
}
