@mixin apply-background($theme, $color) {
  &-theme-#{$theme} {
    .ReviewStoryTemplateCard-bg {
      background: $color;
      color: invert($color);
    }
  }
}

@mixin line-clamp($lines: 3) {
  display: -webkit-box;
  -webkit-line-clamp: $lines;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

@mixin fit-parent {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.ReviewStoryTemplateCard {
  display: grid;
  align-items: center;

  &-heading {
    font-size: 2rem;
  }
  &-comments {
    font-size: 1.5rem;
  }
  &-customer {
    font-size: 1.3rem;
    &:before {
      content: '- ';
    }
  }

  @include apply-background(light, white);
  @include apply-background(dark, black);

  &:not(.ReviewStoryTemplateCard-story-1) {
    &-image {
      display: grid;
      align-items: stretch;
      position: relative;
      .img {
        img {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
        }
      }
    }
  }
  &-story-1 {
    .ReviewStoryTemplateCard {
      &-bg {
        padding: 1.5rem 2rem;
        display: grid;
        grid-template-areas:
          'love love love logo'
          'star star star star'
          'desc desc desc desc'
          'cust cust cust cust'
          '. img img .'
          'brand brand brand brand';
        grid-template-columns: repeat(4, 1fr);
      }

      &-heading {
        grid-area: love;
        align-items: end;
        display: grid;
      }
      &-logo {
        display: grid;
        align-items: start;
        justify-content: center;
        grid-area: logo;
        .img {
          $size: 96px;
          height: $size;
          width: $size;
        }
      }
      &-rating {
        grid-area: star;
        font-size: 3rem;
      }
      &-comments {
        padding: 15px 0;
        display: grid;
        align-items: center;
        grid-area: desc;
        font-size: 1.5rem;
        span {
          @include line-clamp(8);
        }
      }
      &-customer {
        grid-area: cust;
      }

      &-image {
        padding-top: 15px;
        grid-area: img;
        display: grid;
        align-items: center;
        position: relative;
        margin-bottom: 15px;
        .brush-stroke {
          position: absolute;
          &:first-of-type {
            top: -7%;
            left: -45%;
            width: 100%;
            transform-origin: center;
            transform: rotate(-45deg);
          }
          &:nth-of-type(2) {
            bottom: -10%;
            right: -45%;
            width: 100%;
            transform-origin: center;
            transform: rotate(180deg - 45deg);
          }
        }
      }

      &-verified-by {
        grid-area: brand;
        display: grid;
        align-items: center;
        margin-top: 20px;
      }
    }
  }

  &-story-2 {
    .ReviewStoryTemplateCard {
      &-bg {
        display: grid;
        grid-template-areas:
          'img1 img1 logo1 logo1'
          'logo2 logo2 img2 img2'
          'love love love love'
          'desc desc desc desc'
          'cust cust cust cust'
          'brand brand brand brand';
        grid-template-rows: 1fr 1fr auto auto auto auto;
        grid-template-columns: repeat(4, 1fr);
      }

      &-heading {
        grid-area: love;
        text-align: center;
        padding-top: 15px;
        font-size: 2rem;
        order: 5;
      }
      &-logo {
        display: grid;
        align-items: center;
        justify-content: center;
        .img {
          $size: 110px;
          height: $size;
          width: $size;
        }
        &:first-of-type {
          grid-area: logo1;
          order: 2;
          padding: 0 0 20% 20%;
        }
        &:nth-of-type(2) {
          grid-area: logo2;
          order: 3;
          padding: 20% 20% 0 0;
        }
      }
      &-rating {
        display: none;
      }
      &-comments {
        padding: 15px 2rem;
        grid-area: desc;
        order: 6;
        font-size: 1.5rem;
        span {
          @include line-clamp(3);
        }
      }
      &-customer {
        grid-area: cust;
        order: 7;
        text-align: center;
      }

      &-image {
        display: grid;
        align-items: stretch;
        .img {
          img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
          }
        }

        &:nth-of-type(7) {
          transform: scale(1.2) translate(8%, 8%);
          grid-area: img1;
          order: 1;
        }
        &:nth-of-type(8) {
          transform: scale(1.2) translate(-8%, -8%);
          order: 4;
          grid-area: img2;
        }
      }

      &-verified-by {
        padding: 15px;
        grid-area: brand;
        display: grid;
        align-items: center;
        order: 8;
      }
    }
  }
  &-story-3 {
    .ReviewStoryTemplateCard {
      &-bg {
        display: grid;
        grid-template-rows: 1fr auto auto auto auto;
        grid-template-columns: repeat(4, 1fr);
        grid-template-areas:
          'img img img img'
          'love love love love'
          'desc desc desc desc'
          'cust cust cust cust'
          'brand brand brand logo';
        padding-bottom: 1.5rem;
      }
      &-image {
        order: 1;
        grid-area: img;
        display: grid;
        align-items: stretch;
        position: relative;
        .img {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
          }
        }
      }

      &-heading {
        order: 2;
        grid-area: love;
        text-align: center;
        padding: 15px;
      }

      &-comments {
        padding: 0 2rem;
        grid-area: desc;
        order: 3;
        font-size: 1.5rem;
        span {
          @include line-clamp(3);
        }
      }

      &-customer {
        order: 4;
        grid-area: cust;
        text-align: center;
        margin: 0.8rem 0;
      }

      &-verified-by {
        order: 5;
        display: grid;
        align-items: end;
        grid-area: brand;
        padding-bottom: 5px;
        padding-right: 45px;
        text-align: right;
        align-self: end;
      }

      &-logo {
        order: 6;
        grid-area: logo;
        .img {
          $size: 96px;
          height: $size;
          width: $size;
        }
      }

      &-rating {
        display: none;
      }
    }
  }

  &-story-4 {
    .ReviewStoryTemplateCard {
      &-bg {
        padding: 1rem 2rem;
        display: grid;
        grid-template-rows: auto 1fr auto auto auto auto auto;
      }
      &-logo {
        display: grid;
        justify-content: center;
        margin-bottom: 20px;
        order: 1;
        .img {
          $size: 110px;
          height: $size;
          width: $size;
        }
      }
      &-image {
        display: grid;
        align-items: stretch;
        order: 2;
        margin-bottom: 20px;
        position: relative;
        .img {
          @include fit-parent();
          img {
            @include fit-parent();
          }
        }
      }

      &-heading {
        order: 3;
        text-align: center;
        margin-bottom: 10px;
      }
      &-rating {
        text-align: center;
        order: 4;
        font-size: 1.8rem;
      }

      &-comments {
        order: 5;
        margin: 15px 0;
        text-align: center;
        span {
          @include line-clamp(5);
        }
      }

      &-customer {
        order: 6;
        text-align: center;
        margin-bottom: 15px;
      }

      &-verified-by {
        order: 7;
      }
    }
  }
}
