.StarRating {
  --fill: 50%;
  --fill-color: var(--user-theme-color-primary);
  span {
    color: var(--fill-color);
    background: linear-gradient(
      to right,
      var(--fill-color) calc(var(--fill) / 4),
      var(--fill-color) calc(var(--fill)),
      rgba(gray, 0.3) var(--fill)
    );
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}
