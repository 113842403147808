.Calendar {
  .calendar {
    &-day {
      &-today {
        .calendar-day-number {
          background: transparent;
          color: var(--f7-calendar-selected-bg-color, var(--f7-theme-color));
          font-weight: 500;
        }
        &.calendar-day-selected {
          .calendar-day-number {
            color: white;
            background: var(
              --f7-calendar-selected-bg-color,
              var(--f7-theme-color)
            );
          }
        }
      }
    }
  }
}
