.GenerateReviewCard {
  &-theme {
    &-default {
      .star-fill {
        &:before {
          content: '\2605';
        }
      }
      .ReviewCard-bg {
        background: linear-gradient(to right, #01b1ea 20%, #82318e 100%);
        padding: 2px;
      }
      .flayr-logo {
        /* Fallback: Set a background color. */
        background-color: #82318e;

        /* Create the gradient. */
        background-image: linear-gradient(to right, #01b1ea, #82318e);

        /* Set the background size and repeat properties. */
        background-size: 100%;
        background-repeat: repeat;

        /* Use the text as a mask for the background. */
        /* This will show the gradient as a text color rather than element bg. */
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        -moz-background-clip: text;
        -moz-text-fill-color: transparent;
      }
    }
  }
}
