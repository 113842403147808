.Kit {
  &-input {
    button {
      position: absolute;
      height: 40px;
      top: calc(50% + 3px);
      transform: translateY(-50%);
      right: 0;
      width: 40px;
      z-index: 2;
      outline: none;
      &:first-of-type {
        right: 40px;
      }
    }
  }
}
