.CheckoutForm {
  &-stripe {
    border-radius: var(--f7-input-outline-border-radius);
    padding-left: var(--f7-input-outline-padding-horizontal);
    padding-right: var(--f7-input-outline-padding-horizontal);
    width: 100%;
    height: var(--f7-input-height);
    color: var(--f7-input-text-color);
    font-size: var(--f7-input-font-size);

    position: relative;

    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border: 1px solid var(--f7-input-outline-border-color);
      border-radius: inherit;
    }

    &-focused {
      &:before {
        border: 2px solid var(--color-primary);
      }
    }
  }
}
