.ReadMore {
  --lines: 3;
  &-content {
    position: relative;
    overflow: hidden;
    line-height: 1.2em;
    counter-reset: line;
    &:not(.visible) {
      display: -webkit-box;
      -webkit-line-clamp: var(--lines);
      -webkit-box-orient: vertical;
    }
    &.visible {
      height: auto;
      &:after {
        display: none;
      }
    }
  }
}
