.Img {
  overflow: hidden;

  &-grayscale {
    img {
      filter: grayscale(1);
    }
  }
  &:not(.no-cover) {
    img {
      object-fit: cover;
      &.position-top {
        object-position: top;
      }
      &.position-bottom {
        object-position: bottom;
      }
      &.position-center {
        object-position: center;
      }
    }
  }
}
