@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,500;1,300&display=swap');

@import './tailwind-compiled';
@import './img-helpers';

html {
  font-family: 'Roboto', sans-serif;
}

:root {
  --ios-gray: #eaeaea;
  --flayr-primary: #bc9cff;
  --flayr-primary-alt: #8ba4f9;
  --flayr-gradient-primary: linear-gradient(
    180deg,
    var(--flayr-primary) 0%,
    var(--flayr-primary-alt) 100%
  );
  --flayr-gradient-text-primary: -webkit-linear-gradient(
    180deg,
    var(--flayr-primary) 0%,
    var(--flayr-primary-alt) 100%
  );

  --color-primary: #2d93ff;
  --color-primary-alt: #dcedff;

  --nishi-gradient-text-primary: -webkit-linear-gradient(
    -90deg,
    var(--color-primary) 55%,
    var(--color-primary-alt) 100%
  );

  --user-theme-color-primary: #007aff;
  --user-theme-color-heading: black;
}

.text {
  &-flayr-gradient-primary {
    color: var(--flayr-primary);
    background: var(--flayr-gradient-text-primary);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  &-nishi-gradient-primary {
    color: var(--nishi-primary);
    background: var(--nishi-gradient-text-primary);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  &-current {
    color: currentColor;
  }

  &-clip {
    color: var(--user-theme-color-primary);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}

@for $num from 1 through 5 {
  .backdrop-blur-#{$num * 10} {
    backdrop-filter: blur($num * 10px);
  }
}

@mixin gradient($direction, $color1, $color2) {
  background: linear-gradient($direction, $color1, $color2);
}
// background
.bg {
  &-insta {
    background: linear-gradient(
      45deg,
      #833ab4,
      #c13584,
      #e1306c,
      #fd1d1d,
      #fd941d
    );
  }

  &-gradient {
    &-blue {
      @include gradient(180deg, #4bd9fd, #1e6df1);
    }
    &-green {
      @include gradient(180deg, #b6fde2, #5ac8d6);
    }
    &-purple {
      background: linear-gradient(180deg, rgba(188, 156, 255, 0.2) 0%, #bc9cff);
    }
    &-gray {
      background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0.2),
        rgba(0, 0, 0, 0.8)
      );
    }
  }
}

.star {
  &:before {
    content: '\2605';
  }
  &-outline {
    &:before {
      content: '☆';
    }
  }
}

// google autocomplete
.pac-container {
  z-index: 14000;
}

// cogotoast
#ct-container {
  z-index: 2147483647 !important;
}

// override
.actions-group {
  background: white;
}
.page-content {
  background: white;
}
.calendar-sheet {
  &.modal-in {
    z-index: 13600;
  }
}
.color-picker-sheet-modal {
  height: auto;
}
.input {
  &-label-auto {
    .item-title.item-label {
      width: auto;
    }
  }
}
.searchbar {
  &-inner {
    background-color: white;
  }
  &:after {
    display: none;
  }
}

.button {
  &:focus {
    outline: none;
  }
  &-fill {
    &.active-state {
      background: var(--user-theme-color-primary);
      opacity: 0.8;
    }
  }
}

.stroke-current {
  path {
    stroke: currentColor;
  }
}

.checkbox {
  &-white {
    .icon-checkbox {
      border-color: white;
      background-color: rgba($color: white, $alpha: 0.3);
    }
  }

  &-shadow {
    .icon-checkbox {
      box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1),
        0 1px 2px 0 rgba(0, 0, 0, 0.06);
    }
  }
}

// customization
.navbar {
  &-btn {
    height: 42px;
    width: 42px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

// helpers
.square {
  width: 100%;
  padding-top: 100%;
  position: relative;
  &-inner {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.z {
  &-popup {
    z-index: 11000;
  }
}

// browser specific
@supports (-webkit-appearance: none) {
  .safari\: {
    &pb {
      &-20 {
        padding-bottom: 5rem;
      }
      &-40 {
        padding-bottom: 10rem;
      }
    }
  }
}
